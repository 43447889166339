import { useLazyQuery } from "@apollo/react-hooks";
import React, { useState, useMemo } from "react";
import { PageContext } from "../../lib/context";
import { GET_VEHICLE, SCAN_PASS } from "../../lib/query";
import PoliceScannerView from "./view";

const PoliceScanner = (props) => {
  const [state, setState] = useState({
    option: "bus",
    qr: null,
    scanning: false,
    busInfo: null,
    notFound: false,
  });

  const [
    scanVehicle,
    { called: calledV, loading: loadingV, data: dataV },
  ] = useLazyQuery(GET_VEHICLE);

  const [
    scanPass,
    { called: calledP, loading: loadingP, data: dataP },
  ] = useLazyQuery(SCAN_PASS);

  useMemo(() => {
    if (dataV?.buses?.length) {
      setState({ ...state, scanning: false, busInfo: dataV?.buses?.[0] });
    } else {
      if (state.scanning) setState({ ...state, notFound: true });
    }
  }, [dataV]);

  useMemo(() => {
    if (dataP?.passes?.length) {
      setState({ ...state, scanning: false, busInfo: dataP?.passes?.[0] });
    } else {
      if (state.scanning) setState({ ...state, notFound: true });
    }
  }, [dataP]);

  const handleOptionChange = (e) => {
    const option = e.target.value;
    setState({ ...state, option });
  };

  const handleScan = async (data) => {
    if (data) {
      if (state.option === "bus")
        scanVehicle({
          variables: {
            id: data.text,
          },
        });
      else
        scanPass({
          variables: {
            id: data.text,
          },
        });
    }
  };

  const pageValues = {
    handleOptionChange,
    handleScan,
    state,
    setState,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <PoliceScannerView />
    </PageContext.Provider>
  );
};

export default PoliceScanner;
