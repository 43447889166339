import React, { useContext, useMemo, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Col,
  Typography,
  Radio,
  Select,
} from "antd";
import { PageContext } from "../../../lib/context";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_BUS, UPDATE_USER } from "../../../lib/mutations";

export const CreateUser = () => {
  const { visible, setVisible, row, setId, setShowForm, showForm } = useContext(
    PageContext
  );

  const [createBus] = useMutation(CREATE_BUS);
  const [updateBus] = useMutation(UPDATE_USER);

  const [form] = Form.useForm();

  useMemo(() => {
    if (Object.keys(row).length > 0) {
      form.setFieldsValue({ ...row });
      setVisible({ ...visible, agent: true });
      setId(row.id);
    } else {
      form.resetFields();
    }
  }, [row]);

  const addBus = async (payload) => {
    delete payload["confirmPassword"];
    const response = await createBus({
      variables: {
        object: [{ ...payload }],
      },
    });
    if (response?.data?.insert_buses?.affected_rows) {
      notification.open({
        message: "Successfully created bus",
        type: "success",
      });
      form.resetFields();
      setShowForm(false);
    } else {
      notification.open({
        message: "Failed to create bus",
        type: "error",
      });
    }
  };

  const update = async (payload) => {
    const response = await updateBus({
      variables: {
        where: {
          id: { _eq: row.id },
        },
        set: {
          ...payload,
        },
      },
    });
    if (response?.data?.update_buses?.affected_rows) {
      notification.open({
        message: "Successfully updated bus",
        type: "success",
      });
      form.resetFields();
      setShowForm(false);
    } else {
      notification.open({
        message: "Failed to update bus",
        type: "error",
      });
    }
  };

  const handleFinishForm = async (payload) => {
    if (!Object.keys(row).length) await addBus(payload);
    else await update(payload);
  };

  return (
    <Modal
      visible={showForm}
      title="Create Bus"
      okText="Save"
      okButtonProps={{ style: { display: "none" } }}
      footer={null}
      onCancel={() => setShowForm(false)}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinishForm}>
        <Form.Item
          rules={[{ required: true }]}
          name="company"
          label="Company"
          required
        >
          <Input placeholder="Company" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="plateno"
          label="Plate no."
          required
        >
          <Input placeholder="Plate no." />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="route"
          label="Route"
          required
        >
          <Input placeholder="Route" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="seatingcapacity"
          label="Seating capacity"
          required
        >
          <Input placeholder="Seating capacity" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="terminal"
          label="Terminal"
          required
        >
          <Radio.Group>
            <Radio value={"North"}>North</Radio>
            <Radio value={"South"}>South</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="vehicletype"
          label="Vehicle Type"
          required
        >
          <Select defaultValue="bus">
            <Select.Option key="bus" value="bus">
              Bus
            </Select.Option>
            <Select.Option key="premium-taxi" value="premium-taxi">
              Premium Taxi
            </Select.Option>
            <Select.Option key="V-hire" value="v-hire">
              V-hire
            </Select.Option>
            <Select.Option key="tourist-bus" value="tourist-bus/van">
              Tourist Bus/Van
            </Select.Option>
            <Select.Option key="modern-jeepney" value="modern-jeepney">
              Modern Jeepney
            </Select.Option>
            <Select.Option
              key="bus-special-permit"
              value="bus-with-special-permit"
            >
              Bus with special permit
            </Select.Option>
          </Select>
        </Form.Item>

        <Row>
          <Col span={8}>
            <Button name="submit" htmlType="submit" type="primary">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
