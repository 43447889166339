import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { authGuard, AuthGuard, DashGuard } from "./modules";
import { ApolloProvider } from "@apollo/react-hooks";
import { AppContext } from "../lib/context";
import makeApolloClient from "../lib/apollo";
import login from "../pages/login";
import DashboardController from "../pages/dashboard";
import UsersInfoController from "../pages/user-info";
import BusesController from "../pages/buses";
import DispatcherController from "../pages/dispatchers";
import PrintBusController from "../pages/print-buses";
import PassController from "../pages/passes";
import DispatcherLogin from "../pages/dispatcher-login";
import DispatcherScanController from "../pages/dispatcher-scan";
import PoliceScanner from "../pages/police-scanner";

export default function App() {
  const [client, setClient] = useState(null);

  const { TOKEN } = useContext(AppContext);

  const initClient = async () => {
    let client;
    const token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN);
    if (token) {
      client = makeApolloClient();
    } else {
      client = makeApolloClient();
    }
    setClient(client);
  };

  useEffect(() => {
    initClient();
  }, []);

  if (!client) return null;

  return (
    <Router>
      <ApolloProvider client={client}>
        <Switch>
          <AuthGuard exact path="/" component={DispatcherLogin} />
          <AuthGuard exact path="/police-scanner" component={PoliceScanner} />
          <AuthGuard exact path="/admin" component={login} />
          <DashGuard exact path="/dashboard" component={DashboardController} />
          <DashGuard
            exact
            path="/dispatcher-scan"
            component={DispatcherScanController}
          />
          <DashGuard exact path="/buses" component={BusesController} />
          <DashGuard exact path="/print-buses" component={PrintBusController} />
          <DashGuard exact path="/passes" component={PassController} />
          <DashGuard
            exact
            path="/dispatchers"
            component={DispatcherController}
          />
          <DashGuard exact path="/user-info" component={UsersInfoController} />
        </Switch>
      </ApolloProvider>
    </Router>
  );
}
