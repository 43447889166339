import gql from "graphql-tag";

export const GET_LATEST_DATA = gql`
  query MyQuery {
    passes(order_by: { created_at: desc }, limit: 5) {
      busid
      created_at
      dispatcherid
      id
      passengers
      status
      updated_at
      bus {
        company
        route
        terminal
        seatingcapacity
        plateno
        id
        status
        updated_at
        created_at
        controlno
      }
    }

    buses(order_by: { created_at: desc }, limit: 5) {
      company
      route
      terminal
      seatingcapacity
      plateno
      id
      status
      updated_at
      created_at
      controlno
    }
  }
`;

export const GET_AGGREGATES = gql`
  query MyQuery {
    total: buses_aggregate {
      aggregate {
        count
      }
    }
    dispatchers: dispatchers_aggregate {
      aggregate {
        count
      }
    }
    passes: passes_aggregate {
      aggregate {
        count
      }
    }
    passesnorth: passes_aggregate(
      where: { bus: { terminal: { _eq: "North" } } }
    ) {
      aggregate {
        count
      }
    }
    passessouth: passes_aggregate(
      where: { bus: { terminal: { _eq: "South" } } }
    ) {
      aggregate {
        count
      }
    }
    trips: trips_aggregate {
      aggregate {
        count
      }
    }
    buses: buses_aggregate(where: { vehicletype: { _eq: "bus" } }) {
      aggregate {
        count
      }
    }
    modernjeepney: buses_aggregate(
      where: { vehicletype: { _eq: "modern-jeepney" } }
    ) {
      aggregate {
        count
      }
    }
    premiumtaxi: buses_aggregate(
      where: { vehicletype: { _eq: "premium-taxi" } }
    ) {
      aggregate {
        count
      }
    }
    vhire: buses_aggregate(where: { vehicletype: { _eq: "vhire" } }) {
      aggregate {
        count
      }
    }
    touristbusvan: buses_aggregate(
      where: { vehicletype: { _eq: "tourist-bus/van" } }
    ) {
      aggregate {
        count
      }
    }
    special: buses_aggregate(
      where: { vehicletype: { _eq: "bus-with-special-permit" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_BUSES = gql`
  subscription MyQuery {
    buses(order_by: { created_at: desc }) {
      company
      route
      terminal
      vehicletype
      seatingcapacity
      plateno
      id
      status
      updated_at
      created_at
      controlno
    }
  }
`;

export const GET_PASSES = gql`
  query MyQuery {
    passes(order_by: { created_at: desc }) {
      busid
      created_at
      dispatcherid
      id
      passengers
      status
      updated_at
      tripno
      bus {
        controlno
        company
        route
        plateno
        terminal
        seatingcapacity
      }
    }
  }
`;

export const GET_DISPATCHERS = gql`
  subscription MyQuery {
    dispatchers(
      where: { username: { _neq: "admin" } }
      order_by: { created_at: desc }
    ) {
      id
      username
      password
      status
    }
  }
`;

export const SEARCH_BUS = gql`
  query MyQuery($search: String) {
    buses(
      where: {
        _or: [
          { company: { _ilike: $search } }
          { plateno: { _ilike: $search } }
          { terminal: { _ilike: $search } }
        ]
        status: { _eq: "approved" }
      }
      limit: 50
    ) {
      id
      company
      plateno
      controlno
      seatingcapacity
      route
      terminal
      created_at
    }
  }
`;

export const SCAN_BUS = gql`
  query MyQuery($id: uuid) {
    buses(where: { id: { _eq: $id }, status: { _eq: "approved" } }) {
      company
      plateno
      route
      seatingcapacity
      terminal
    }
  }
`;

export const GET_VEHICLE = gql`
  query MyQuery($id: uuid) {
    buses(where: { id: { _eq: $id } }) {
      company
      controlno
      created_at
      id
      plateno
      route
      seatingcapacity
      status
      terminal
      updated_at
      vehicletype
    }
  }
`;

export const SCAN_PASS = gql`
  query MyQuery($id: uuid) {
    passes(where: { id: { _eq: $id } }) {
      busid
      created_at
      dispatcherid
      id
      passengers
      status
      tripno
      updated_at
      bus {
        company
        controlno
        created_at
        id
        plateno
        route
        seatingcapacity
        status
        terminal
        updated_at
        vehicletype
      }
    }
  }
`;
