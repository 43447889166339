import { Button, Form, InputNumber, Select, Spin } from "antd";
import React, { useContext } from "react";
import { PageContext } from "../../lib/context";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { QRCode } from "react-qrcode-logo";

const DispatcherScanView = () => {
  const {
    handleLogout,
    handleScan,
    handleError,
    isScanning,
    setIsScanning,
    isInputPassengers,
    busData,
    handleFinish,
    handleReset,
    qrPass,
    insertingPass,
    isInputPlate,
    setIsInputPlate,
    busList,
    setBusData,
    setBusId,
  } = useContext(PageContext);

  return (
    <div className="flex flex-col items-center p-20 text-center lg:text-left">
      {!qrPass && !isScanning && !isInputPassengers && !isInputPlate ? (
        <>
          {" "}
          <p className="primary-color text-xl lg:text-3xl font-bold">
            Welcome Dispatcher
          </p>
          <p className="primary-color text-xs lg:text-xl">
            Get a pass by scanning the QR Code of the bus.
          </p>
          <p className="primary-color text-xs lg:text-xl">
            Click the button below to scan.
          </p>
          <Button
            className="my-5"
            type="primary"
            onClick={() => setIsScanning(true)}
          >
            Scan
          </Button>
          <p>Or</p>
          <Button
            className="mt-5"
            type="primary"
            onClick={() => setIsInputPlate(true)}
          >
            Enter plate number
          </Button>
        </>
      ) : (
        !isInputPassengers &&
        !qrPass &&
        !isInputPlate && (
          <>
            <p className="primary-color text-xs lg:text-xl">
              Point the camera to the QR Code.
            </p>
            <BarcodeScannerComponent
              delay={500}
              height={300}
              width={250}
              onUpdate={(err, result) => handleScan(result)}
              facingMode="environment"
            />
          </>
        )
      )}

      {isInputPlate && !isInputPassengers && qrPass === null && (
        <div className="flex flex-col">
          <p className="primary-color text-xs lg:text-xl">
            Choose plate number
          </p>
          <Select
            onChange={(val) => {
              setBusId(val);
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {busList &&
              busList.buses.length > 0 &&
              busList.buses.map((bus) => (
                <Select.Option value={bus.id}>{bus.plateno}</Select.Option>
              ))}
          </Select>
        </div>
      )}

      {isInputPassengers && qrPass === null && (
        <div className="flex flex-col">
          <p>Bus Company: {busData?.company}</p>
          <p>Plate no: {busData?.plateno}</p>
          <p>Route: {busData?.route}</p>
          <p>Terminal: {busData?.terminal}</p>
          <Form layout="vertical" onFinish={handleFinish} className="mt-10">
            <Form.Item
              className="w-full"
              name="passengers"
              label="No. of Passengers"
              rules={[{ required: true }]}
            >
              <InputNumber className="w-full" placeholder="No. of Passengers" />
            </Form.Item>
            <Button disabled={insertingPass} htmlType="submit" type="primary">
              {insertingPass ? <Spin /> : "Get Pass"}
            </Button>
          </Form>
        </div>
      )}

      {qrPass !== null && (
        <>
          <QRCode
            value={qrPass}
            logoWidth={40}
            logoHeight={40}
            logoImage={require("../../assets/cebu-province-logo.jpg")}
          />
          <p className="mt-5">Congratulations, here's your pass!</p>
          <p>You can take a screenshot to save this Qrcode</p>
        </>
      )}

      <a href="#" className="text-blue-400 mt-10" onClick={handleReset}>
        Reset
      </a>

      <a href="#" className="text-blue-400 mt-10" onClick={handleLogout}>
        Logout
      </a>
    </div>
  );
};

export default DispatcherScanView;
