import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Form, Input, Button, Spin, Card, Radio } from "antd";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import moment from "moment";

const PoliceScannerView = (props) => {
  const {
    handleOptionChange,
    loading,
    handleScan,
    state,
    setState,
  } = useContext(PageContext);
  return (
    <div className="flex flex-col h-screen pt-20 items-center">
      {!state.scanning && state.busInfo === null && (
        <>
          <p className="text-xl ">Scan QR Codes</p>
          <p className="text-base mt-10">Choose type of QR Code to scan.</p>
          <Radio.Group defaultValue="bus" onChange={handleOptionChange}>
            <Radio value={"bus"}>Bus QR</Radio>
            <Radio value={"pass"}>Pass QR</Radio>
          </Radio.Group>

          <Button
            type="primary"
            className="mt-5"
            onClick={() => setState({ ...state, scanning: true })}
          >
            Scan
          </Button>
        </>
      )}

      {state.scanning && !state.notFound && (
        <>
          <span className="mt-5">
            <BarcodeScannerComponent
              delay={500}
              height={300}
              width={250}
              onUpdate={(err, result) => handleScan(result)}
              facingMode="environment"
            />
          </span>
          <Button
            className="mt-5"
            onClick={() => setState({ ...state, scanning: false })}
          >
            Hide Scanner
          </Button>
        </>
      )}

      {state.busInfo !== null && (
        <>
          <div className="flex flex-col mt-5">
            {state.option === "bus" ? (
              <>
                <p className="font-bold text-xl">Vehicle Information</p>
                <p>Company: {state?.busInfo?.company}</p>
                <p>Plate no: {state?.busInfo?.plateno}</p>
                <p>Route: {state?.busInfo?.route}</p>
                <p>Terminal: {state?.busInfo?.terminal}</p>
              </>
            ) : (
              <>
                <p className="font-bold text-xl">Pass Information</p>
                <p>Company: {state?.busInfo?.bus?.company}</p>
                <p>Plate no: {state?.busInfo?.bus?.plateno}</p>
                <p>Route: {state?.busInfo?.bus?.route}</p>
                <p>Terminal: {state?.busInfo?.bus?.terminal}</p>
                <p>Status: {state?.busInfo?.status}</p>
                <p>Passengers: {state?.busInfo?.passengers}</p>
                <p>
                  Validity:{" "}
                  {moment(state?.busInfo?.created_at).format(
                    "YYYY-MM-DD HH:mm a"
                  )}{" "}
                  -
                  {moment(state?.busInfo?.created_at)
                    .add(5, "hours")
                    .format("YYYY-MM-DD HH:mm a")}
                </p>
              </>
            )}
            <Button className="mt-5" onClick={() => window.location.reload()}>
              Show Scanner
            </Button>
          </div>
        </>
      )}

      {state.notFound && (
        <div className="flex flex-col mt-5">
          <p className="font-bold text-xl">
            {state.option === "bus" ? "Vehicle QR" : "QR Pass"} not found!
          </p>
          <Button className="mt-5" onClick={() => window.location.reload()}>
            Show Scanner
          </Button>
        </div>
      )}
    </div>
  );
};

export default PoliceScannerView;
