import React, { useEffect, useMemo, useState } from "react";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import _ from "lodash";
import { GET_PASSES } from "../../lib/query";

const PassController = () => {
  const [visible, setVisible] = useState({
    agent: false,
    bulkagent: false,
  });
  const [row, setRow] = useState({});
  const [id, setId] = useState();
  const [showForm, setShowForm] = useState(false);
  const [passData, setPassData] = useState([]);
  const [csvData, setCsvData] = useState([
    ["Timestamp", "Company", "Plate no.", "Route", "Terminal", "Passengers"],
  ]);

  const { data: passes, loading: loading_passes } = useQuery(GET_PASSES);

  useMemo(() => {
    if (passes) {
      const data = [];
      const items = [];
      passes.passes.map((pass, key) => {
        const obj = {
          passengers: pass.passengers,
          tripno: pass.tripno,
          created_at: pass.created_at,
          ...pass.bus,
        };
        data.push(obj);

        const item = [
          obj.created_at,
          obj.company,
          obj.plateno,
          obj.route,
          obj.terminal,
          obj.passengers,
        ];
        items.push(item);

        if (key === passes?.passes?.length - 1) {
          setPassData([...data]);
          setCsvData([...csvData, ...items]);
        }
      });
    }
  }, [passes, loading_passes]);

  const onRowClick = (item) => {
    setRow(item);
    setShowForm(true);
  };

  const pageValues = {
    passData,
    passes,
    loading_passes,
    visible,
    row,
    id,
    showForm,
    setVisible,
    onRowClick,
    setRow,
    setId,
    setShowForm,
    csvData,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default PassController;
