import gql from "graphql-tag";

export const BETS = gql`
  subscription MyQuery($betsWhere: bets_bool_exp) {
    bets(where: $betsWhere, limit: 100, order_by: { created_at: desc }) {
      transactionId
      agentId
      amount
      created_at
      drawNumber
      id
      number
      updated_at
      type
      user {
        id
        username
      }
    }
  }
`;

export const AGENTS = gql`
  subscription MyQuery {
    users(where: { type: { _eq: "agent" } }, order_by: { created_at: desc }) {
      created_at
      email
      firstname
      id
      isActivated
      lastname
      municipality
      type
      updated_at
      username
    }
  }
`;

export const USERS = gql`
  subscription MyQuery {
    users(where: { type: { _eq: "admin" } }, order_by: { created_at: desc }) {
      created_at
      email
      firstname
      id
      isActivated
      lastname
      municipality
      type
      updated_at
      username
    }
  }
`;

export const INDIVIDUAL_LIMITS = gql`
  subscription MySubscription {
    betsLimits(where: { type: { _eq: "individual" } }) {
      created_at
      date
      draw
      id
      limit
      number
      type
      updated_at
      currentLimit
    }
  }
`;

export const GLOBAL_LIMITS = gql`
  subscription MySubscription {
    betsLimits(where: { type: { _eq: "global" } }, order_by: { number: asc }) {
      created_at
      date
      draw
      id
      limit
      number
      type
      updated_at
      currentLimit
    }
  }
`;

export const RESULTS = gql`
  subscription MySubscription {
    results(order_by: { date: desc }) {
      combination
      created_at
      date
      drawNumber
      id
      updated_at
    }
  }
`;

export const SERVER_CONFIGS = gql`
  subscription MySubscription {
    serverConfigs {
      created_at
      id
      type
      updated_at
      value
    }
  }
`;

export const GET_BULK_AGENTS_COUNT = gql`
  subscription MySubscription {
    users_aggregate(where: { username: { _like: "AGT%" } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_BULK_AGENTS_NUMBER = gql`
  subscription MySubscription2 {
    users(where: { username: { _like: "4%" } }, order_by: { username: desc }) {
      username
    }
  }
`;
export const GET_BULK_AGENTS = gql`
  subscription MySubscription {
    users_aggregate(where: { username: { _like: "AGT%" } }) {
      aggregate {
        count
      }
    }
  }
`;

export const BET_WINNERS = gql`
  query MyQuery($resultsWhere: results_bool_exp, $betsWhere: bets_bool_exp) {
    results(where: $resultsWhere, order_by: { created_at: desc }) {
      combination
      created_at
      date
      drawNumber
      id
      updated_at
      bets(where: $betsWhere) {
        amount
        agentId
        created_at
        drawNumber
        id
        number
        type
        updated_at
        user {
          username
        }
      }
    }
  }
`;

export const BETS_LIST = gql`
  query MyQuery($date: timestamptz) {
    bets(
      where: { created_at: { _gte: $date } }
      order_by: { created_at: desc }
    ) {
      drawNumber
      number
    }
  }
`;
