import React, { useContext, useMemo, useRef, useState } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { PageContext } from "../../../lib/context";
import ReactToPrint from "react-to-print";
import { QRCode } from "react-qrcode-logo";
import moment from "moment";

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div
        className="text-gray-700"
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "row",
          width: 1150,
          flexWrap: "wrap",
          textAlign: "left",
          alignItems: "center",
        }}
      >
        {this.props.selected.map((id, key) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundImage: `url(${
                id?.terminal === "South"
                  ? require("../../../assets/pass-template.jpg")
                  : require("../../../assets/north-pass.png")
              })`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              height: 780,
              width: 700,
              marginRight: 2,
              objectFit: "fill",
              position: "relative",
            }}
          >
            <div
              style={{
                height: 50,
                width: 50,
                position: "absolute",
                left: 353,
                top: 58,
              }}
            >
              <QRCode
                value={id?.id}
                logoWidth={30}
                logoHeight={30}
                logoImage={require("../../../assets/cebu-province-logo.jpg")}
                size={110}
              />
            </div>

            <div
              style={{
                position: "absolute",
                left: 294,
                top: 606,
              }}
            >
              <p
                style={{
                  fontSize: 109,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {id?.seatingcapacity}
              </p>
            </div>
            <div>
              <p
                style={{
                  fontSize: 200,
                  fontWeight: "bold",
                  color: "black",
                  position: "absolute",
                  top: 193,
                  left: 171,
                }}
              >
                {id?.passengers}
              </p>
            </div>
            <div>
              <p
                style={{
                  fontSize: 20,
                  position: "absolute",
                  color: "black",
                  top: 477,
                  left: 72,
                }}
              >
                {id?.plateno}
              </p>
            </div>
            <div>
              <p
                style={{
                  fontSize: 12,
                  position: "absolute",
                  color: "black",
                  top: 555,
                  left: 53,
                }}
              >
                {id?.route?.toUpperCase()}
              </p>
            </div>
            <div>
              <p
                style={{
                  fontSize: 14,
                  position: "absolute",
                  color: "black",
                  top: 482,
                  left: 279,
                }}
              >
                {moment(id?.created_at).format("LL H:mm a")}
              </p>
            </div>
            {id?.terminal === "North" && (
              <div>
                <p
                  style={{
                    fontSize: 14,
                    position: "absolute",
                    color: "black",
                    top: 552,
                    left: 295,
                  }}
                >
                  {moment(id?.created_at).add(5, "hours").format("LL H:mm a")}
                </p>
              </div>
            )}

            <div>
              <p
                style={{
                  fontSize: 14,
                  position: "absolute",
                  color: "black",
                  top: id?.terminal === "South" ? 552 : 628,
                  left: id?.terminal === "South" ? 328 : 71,
                }}
              >
                BPS - {id?.tripno}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export const CreateUser = () => {
  const { row, setId, setShowForm, showForm } = useContext(PageContext);

  const componentRef = useRef();
  return (
    <Modal
      visible={showForm}
      title="Print pass"
      okText="Save"
      okButtonProps={{ style: { display: "none" } }}
      footer={null}
      onCancel={() => setShowForm(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      width={900}
    >
      <ReactToPrint
        trigger={() => <button>Print</button>}
        content={() => componentRef.current}
      />
      <ComponentToPrint ref={componentRef} selected={[row]} />
    </Modal>
  );
};
