import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Spin, Table } from "antd";
import { bets_columns } from "./tables";
import { CheckCircleFilled } from "@ant-design/icons";

const DashboardView = () => {
  const { data, loading, aggregates, loadingaggregates } = useContext(
    PageContext
  );

  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-3xl font-bold secondary-color">Admin Dashboard</p>
      <div className="flex flex-row mt-5">
        <div className="w-1/5 border mr-5 rounded-lg px-5 py-5 secondary-color">
          <div className="flex flex-row items-center">
            <p className="text-base font-bold secondary-color">
              Total number of vehicles
            </p>
          </div>
          <p className=" tertiary-color mt-5 text-base font-bold">
            {aggregates?.total?.aggregate?.count ?? 0} Total vehicles
          </p>
          <p className=" tertiary-color mt-5 text-base font-bold">
            {aggregates?.buses?.aggregate?.count ?? 0} Buses
          </p>
          <p className=" tertiary-color mt-5 text-base font-bold">
            {aggregates?.modernjeepney?.aggregate?.count ?? 0} Modern Jeepney
          </p>
          <p className=" tertiary-color mt-5 text-base font-bold">
            {aggregates?.touristbusvan?.aggregate?.count ?? 0} Tourist Bus/Van{" "}
          </p>
          <p className=" tertiary-color mt-5 text-base font-bold">
            {aggregates?.premiumtaxi?.aggregate?.count ?? 0} Premium Taxi{" "}
          </p>
          <p className=" tertiary-color mt-5 text-base font-bold">
            {aggregates?.vhire?.aggregate?.count ?? 0} Vhire{" "}
          </p>
          <p className=" tertiary-color mt-5 text-base font-bold">
            {aggregates?.special?.aggregate?.count ?? 0} Bus with Special permit
          </p>
        </div>
        <div className="w-1/5 border rounded-lg px-5 py-5 mr-3 secondary-color">
          <div className="flex flex-row items-center">
            <p className="text-base font-bold secondary-color">
              Total number of dispatchers
            </p>
          </div>
          <p className="tertiary-color mt-5 text-base font-bold">
            {aggregates?.dispatchers?.aggregate?.count ?? 0} Dispatchers
          </p>
        </div>
        <div className="w-1/5 border rounded-lg px-5 py-5 mr-3 secondary-color">
          <div className="flex flex-row items-center">
            <p className="text-base font-bold secondary-color">Total passes</p>
          </div>
          <p className="tertiary-color mt-5 text-base font-bold">
            {aggregates?.passes?.aggregate?.count ?? 0} Passes
          </p>
          <p className="tertiary-color mt-5 text-base font-bold">
            {aggregates?.passesnorth?.aggregate?.count ?? 0} North
          </p>
          <p className="tertiary-color mt-5 text-base font-bold">
            {aggregates?.passessouth?.aggregate?.count ?? 0} South
          </p>
        </div>
      </div>
      <div className="flex flex-row mt-5">
        <div className="w-2/4 mr-2">
          <div>
            <p className="font-bold">Latest passes</p>
            {data?.passes?.length > 0
              ? data.passes.map((pass) => (
                  <div className="p-5 bg-gray-200 m-2 rounded-lg items-center flex flex-row self-start">
                    <div className="flex flex-row items-center mr-20">
                      <CheckCircleFilled className="mr-3 text-lg text-blue-400" />
                      <div>
                        <p className="">
                          Company: {pass?.bus?.company?.toUpperCase()} -{" "}
                          {pass?.bus?.terminal?.toUpperCase()},{" "}
                        </p>
                        <p className="">
                          Plate no: {pass?.bus?.plateno?.toUpperCase()},{" "}
                          {pass?.bus?.terminal?.toUpperCase()},{" "}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="">
                        Route: {pass?.bus?.route?.toUpperCase()}
                      </p>
                      <p className="">
                        Terminal: {pass?.bus?.terminal?.toUpperCase()},{" "}
                        <span className="font-bold">
                          {pass?.passengers} passengers
                        </span>
                      </p>
                    </div>
                  </div>
                ))
              : "No new passes"}
          </div>
        </div>
        <div className="w-2/4">
          <p className="font-bold">New buses</p>
          {data?.buses?.length > 0
            ? data.buses.map((bus) => (
                <div className="p-5 bg-orange-200 m-2 rounded-lg items-center flex flex-row justify-between">
                  <p className="font-bold">
                    Company: {bus?.company?.toUpperCase()} -{" "}
                    {bus?.terminal?.toUpperCase()}
                  </p>
                  <p className="font-bold">
                    {" "}
                    Plate no: {bus?.plateno?.toUpperCase()},{" "}
                    {bus?.terminal?.toUpperCase()}
                  </p>
                  <p className="font-bold">
                    Route: {bus?.route?.toUpperCase()}
                  </p>
                  <p className="font-bold">
                    Terminal: {bus?.terminal?.toUpperCase()}{" "}
                  </p>
                </div>
              ))
            : "No new buses"}
        </div>
      </div>
    </div>
  );
};

export default DashboardView;
