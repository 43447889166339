import React, { useContext, useMemo, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Col,
  Typography,
  Radio,
} from "antd";
import { PageContext } from "../../../lib/context";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_DISPATCHER, UPDATE_DISPATCHER } from "../../../lib/mutations";

export const CreateUser = () => {
  const { visible, setVisible, row, setId, setShowForm, showForm } = useContext(
    PageContext
  );

  const [createDispatcher] = useMutation(CREATE_DISPATCHER);
  const [updateDispatcher] = useMutation(UPDATE_DISPATCHER);

  const [form] = Form.useForm();

  useMemo(() => {
    if (Object.keys(row).length > 0) {
      form.setFieldsValue({ ...row });
      setVisible({ ...visible, agent: true });
      setId(row.id);
    } else {
      form.resetFields();
    }
  }, [row]);

  const addDispatcher = async (payload) => {
    const response = await createDispatcher({
      variables: {
        object: { ...payload },
      },
    });
    if (response?.data?.create_dispatcher?.code == 200) {
      notification.open({
        message: "Successfully created dispatcher",
        type: "success",
      });
      form.resetFields();
      setShowForm(false);
    } else {
      notification.open({
        message: "Failed to create dispatcher",
        type: "error",
      });
    }
  };

  const update = async (payload) => {
    const response = await updateDispatcher({
      variables: {
        where: {
          id: { _eq: row.id },
        },
        set: {
          ...payload,
        },
      },
    });
    if (response?.data?.update_dispatchers?.affected_rows) {
      notification.open({
        message: "Successfully updated dispatcher",
        type: "success",
      });
      form.resetFields();
      setShowForm(false);
    } else {
      notification.open({
        message: "Failed to update dispatcher",
        type: "error",
      });
    }
  };

  const handleFinishForm = async (payload) => {
    if (!Object.keys(row).length) await addDispatcher(payload);
    else await update(payload);
  };

  return (
    <Modal
      visible={showForm}
      title="Create Dispatcher"
      okText="Save"
      okButtonProps={{ style: { display: "none" } }}
      footer={null}
      onCancel={() => setShowForm(false)}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinishForm}>
        <Form.Item
          rules={[{ required: true }]}
          name="username"
          label="Username"
          required
        >
          <Input placeholder="Username" />
        </Form.Item>
        {Object.keys(row).length === 0 && (
          <Form.Item
            rules={[{ required: true }]}
            name="password"
            label="Password"
            required
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
        )}
        <Row>
          <Col span={8}>
            <Button name="submit" htmlType="submit" type="primary">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
