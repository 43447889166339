import gql from "graphql-tag";

export const LOGIN = gql`
  mutation MyMutation($object: LoginInput!) {
    login(object: $object) {
      code
      message
      token
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation MyMutation($password: String!, $user_id: String!) {
    reset_password(object: { password: $password, user_id: $user_id }) {
      code
      message
    }
  }
`;

export const CREATE_BUS = gql`
  mutation MyMutation($object: [buses_insert_input!]!) {
    insert_buses(objects: $object) {
      affected_rows
    }
  }
`;

export const CREATE_DISPATCHER = gql`
  mutation MyMutation($object: CreateDispatcherInput!) {
    create_dispatcher(object: $object) {
      code
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation MyMutation($where: buses_bool_exp!, $set: buses_set_input) {
    update_buses(where: $where, _set: $set) {
      affected_rows
    }
  }
`;

export const UPDATE_DISPATCHER = gql`
  mutation MyMutation(
    $where: dispatchers_bool_exp!
    $set: dispatchers_set_input
  ) {
    update_dispatchers(where: $where, _set: $set) {
      affected_rows
    }
  }
`;

export const INSERT_LIMIT = gql`
  mutation MyMutation($object: betsLimits_insert_input!) {
    insert_betsLimits_one(object: $object) {
      id
    }
  }
`;

export const DELETE_LIMIT = gql`
  mutation MyMutation($id: uuid, $type: String) {
    delete_betsLimits(where: { id: { _eq: $id }, type: { _eq: $type } }) {
      affected_rows
    }
  }
`;

export const UPDATE_GLOBAL = gql`
  mutation MyMutation($currentLimit: Int, $limit: Int) {
    update_betsLimits(
      where: { type: { _eq: "global" } }
      _set: { currentLimit: $currentLimit, limit: $limit }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_RESULT = gql`
  mutation MyMutation($object: results_insert_input!) {
    insert_results_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_SERVER_CONFIG = gql`
  mutation MyMutation($type: String, $value: String) {
    update_serverConfigs(
      where: { type: { _eq: $type } }
      _set: { type: $type, value: $value }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_AGENT = gql`
  mutation MyMutation($id: uuid) {
    delete_users(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const CREATE_BULK_AGENT = gql`
  mutation MyMutation($objects: [users_insert_input!]!) {
    insert_users(objects: $objects) {
      returning {
        username
      }
    }
  }
`;

export const UPDATE_USER_STATUS = gql`
  mutation MyMutation($id: uuid, $status: String) {
    update_buses(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const UPDATE_DISPATCHER_STATUS = gql`
  mutation MyMutation($id: uuid, $status: String) {
    update_dispatchers(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const INSERT_PASS = gql`
  mutation MyMutation($object: [passes_insert_input!]!) {
    insert_passes(objects: $object) {
      affected_rows
    }
  }
`;
