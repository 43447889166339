import React, { useCallback, useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import DashboardView from "./view";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useMemo } from "react";
import _ from "lodash";
import { PageContext } from "../../lib/context";
import { SEARCH_BUS } from "../../lib/query";

const PrintBusController = (props) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const searchTextDebouncer = useCallback(_.debounce(setSearch, 500), []);

  const { data, loading } = useQuery(SEARCH_BUS, {
    variables: {
      search,
    },
  });

  useMemo(() => {
    if (data && !loading) {
      if (data?.buses?.length) {
        const array = [];
        data.buses.map((user, key) => {
          let temp = {
            label: `${user?.company?.toUpperCase()} ${user?.plateno?.toUpperCase()}`,
            value: JSON.stringify(user),
          };
          array.push(temp);
          setSelected([{ ...user }]);
          if (key === data?.buses?.length - 1) {
            setOptions([...array]);
          }
        });
      }
    }
  }, [data]);

  const onSearch = (searchText) => {
    searchTextDebouncer("%" + searchText + "%");
    setValue(searchText);
  };

  const onSelect = async (data) => {
    let parsed = JSON.parse(data);
    setSelected([{ ...parsed }]);
    setValue(null);
  };

  const handleDelete = (key) => {
    setSelected(
      selected.filter(function (item, index) {
        return index !== key;
      })
    );
  };

  const pageValues = {
    data,
    loading,
    options,
    onSearch,
    selected,
    setSelected,
    onSelect,
    value,
    setValue,
    handleDelete,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default PrintBusController;
