import React, { useMemo, useState } from "react";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import _ from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { GET_AGGREGATES, GET_LATEST_DATA } from "../../lib/query";

const DashboardController = () => {
  const { data, loading } = useQuery(GET_LATEST_DATA);
  const { data: aggregates, loading: loadingaggregates } = useQuery(
    GET_AGGREGATES
  );

  const pageValues = {
    data,
    loading,
    aggregates,
    loadingaggregates,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default DashboardController;
