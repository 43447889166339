import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import GuestLayout from "../layout/guest";
import jwt_decode from "jwt-decode";

export const AuthGuard = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !localStorage.getItem(process.env.REACT_APP_LS_TOKEN) ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/dashboard"} />
        )
      }
    />
  );
};

export const DashGuard = ({ component: Component, ...rest }) => {
  const [redirectDispatcher, setRedirectDispatcher] = useState(false);
  const [isDispatcher, setIsDispatcher] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN);
    if (token) {
      const decoded = jwt_decode(token);
      const claims = decoded["https://hasura.io/jwt/claims"];
      if (claims["x-hasura-default-role"] === "dispatcher") {
        setIsDispatcher(true);
        if (rest.path !== "/dispatcher-scan") {
          setRedirectDispatcher(true);
        }
      }
    }
  }, [rest]);

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem(process.env.REACT_APP_LS_TOKEN) ? (
          redirectDispatcher ? (
            <Redirect to="/dispatcher-scan" />
          ) : isDispatcher ? (
            <Component {...props} />
          ) : (
            <GuestLayout>
              <Component {...props} />
            </GuestLayout>
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};
