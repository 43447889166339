import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/react-hooks";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { PageContext } from "../../lib/context";
import { GET_BUSES, SCAN_BUS } from "../../lib/query";
import DispatcherScanView from "./view";
import jwt_decode from "jwt-decode";
import { INSERT_PASS } from "../../lib/mutations";

const DispatcherScanController = () => {
  const [isScanning, setIsScanning] = useState(false);
  const [isInputPlate, setIsInputPlate] = useState(false);
  const [isInputPassengers, setIsInputPassengers] = useState(false);
  const [busData, setBusData] = useState(null);
  const [qrPass, setQrPassId] = useState(null);
  const [busId, setBusId] = useState(null);

  const history = useHistory();

  const [scanBus, { called, loading, data }] = useLazyQuery(SCAN_BUS);
  const [insertPass, { loading: insertingPass }] = useMutation(INSERT_PASS);

  const { data: busList, loading: loadingBusList } = useSubscription(GET_BUSES);

  useMemo(() => {
    if (data?.buses?.length) {
      setIsInputPassengers(true);
      setIsScanning(false);
      setBusData(data?.buses?.[0]);
    } else {
      if (busId) {
        alert("Bus not found");
      }
    }
  }, [data]);

  const handleLogout = () => {
    localStorage.removeItem(process.env.REACT_APP_LS_TOKEN);
    history.push("/");
  };

  const handleError = (err) => {
    console.log("err", err);
  };

  const handleScan = async (data) => {
    if (data) {
      setQrPassId(null);
      scanBus({
        variables: {
          id: data.text,
        },
      });
      setBusId(data.text);
    }
  };

  const handleFinish = async (data) => {
    const token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN);
    if (token) {
      const decoded = jwt_decode(token);
      const claims = decoded["https://hasura.io/jwt/claims"];
      const user_id = claims["x-hasura-user-id"];

      const pass = await insertPass({
        variables: {
          object: [
            {
              ...data,
              dispatcherid: user_id,
              busid: busId,
            },
          ],
        },
      });

      if (pass?.data?.insert_passes?.affected_rows) {
        setQrPassId(pass?.data?.insert_pass?.returning?.[0]?.id);
      }
    }
  };

  useMemo(() => {
    if (busId && isInputPlate && !busData) {
      const filter = busList.buses.filter((el) => el.id === busId);
      setIsInputPassengers(true);
      setIsScanning(false);
      setBusData(filter[0]);
    }
  }, [busId]);

  const handleReset = () => {
    setBusData(null);
    setIsScanning(false);
    setIsInputPassengers(false);
    setQrPassId(null);
    setIsInputPlate(false);
    setBusId(null);
  };

  const pageValues = {
    insertingPass,
    busData,
    isInputPassengers,
    isScanning,
    setIsScanning,
    handleLogout,
    handleError,
    handleScan,
    called,
    loading,
    handleFinish,
    qrPass,
    handleReset,
    isInputPlate,
    setIsInputPlate,
    busList,
    setBusId,
    setBusData,
  };
  return (
    <PageContext.Provider value={pageValues}>
      <DispatcherScanView />
    </PageContext.Provider>
  );
};

export default DispatcherScanController;
