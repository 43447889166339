import React, { useRef, useState } from "react";
import { useContext } from "react";
import { AppContext, PageContext } from "../../lib/context";
import { Form, Input, Button, Spin, AutoComplete } from "antd";
import { useEffect } from "react";
import { useMemo } from "react";
import ReactToPrint from "react-to-print";
import { QRCode } from "react-qrcode-logo";

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div
        className="text-gray-700"
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "row",
          width: 1150,
          flexWrap: "wrap",
          textAlign: "left",
          alignItems: "center",
        }}
      >
        {this.props.selected.map((id, key) => (
          <div
            onClick={() => this.props.handleDelete(key)}
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundImage: `url(${require("../../assets/bus-template.png")})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              height: 780,
              width: 1850,
              marginTop: 10,
              marginLeft: 50,
              marginRight: 2,
              objectFit: "fill",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 668,
                top: 411,
              }}
            >
              <QRCode
                value={id?.id}
                logoWidth={40}
                logoHeight={40}
                logoImage={require("../../assets/cebu-province-logo.jpg")}
                size={250}
              />
            </div>
            <div>
              <p
                style={{
                  fontSize: 220,
                  fontWeight: "bold",
                  color: "black",
                  position: "absolute",
                  top: 365,
                  left: 61,
                }}
              >
                {id?.seatingcapacity}
              </p>
            </div>
            <div>
              <p
                style={{
                  fontSize: 30,
                  position: "absolute",
                  color: "black",
                  top: 260,
                  left: 120,
                }}
              >
                {id?.plateno}
              </p>
            </div>
            <div>
              <p
                style={{
                  fontSize: 30,
                  position: "absolute",
                  color: "black",
                  top: 260,
                  left: 730,
                }}
              >
                CBP - {id?.controlno}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const DashboardView = (props) => {
  const {
    data,
    loading,
    onSelect,
    onSearch,
    selected,
    setSelected,
    options,
    value,
    handleDelete,
  } = useContext(PageContext);

  const componentRef = useRef();
  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-3xl font-bold secondary-color">Print Bus</p>
      <div className="mt-10">
        <p>Search user by company, plate no. or terminal</p>
        <AutoComplete
          options={options}
          onSelect={onSelect}
          onSearch={onSearch}
          placeholder="Search buses"
          allowClear
          value={value}
          style={{ width: 500 }}
        >
          <Input.Search placeholde="Search by company" />
        </AutoComplete>
      </div>
      <div className="mt-10">
        {selected?.length > 0 && (
          <div className="text-left ml-16 text-blue-400 font-bold">
            <ReactToPrint
              trigger={() => <button>Print</button>}
              content={() => componentRef.current}
            />
            <ComponentToPrint
              ref={componentRef}
              selected={selected}
              handleDelete={handleDelete}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardView;
