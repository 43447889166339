import React from "react";
import { useContext, useState, useRef } from "react";
import { PageContext } from "../../lib/context";
import { Button, Spin, Table, Input, Space, Switch } from "antd";
import { UserOutlined, SearchOutlined, EditOutlined } from "@ant-design/icons";
import { dispatcher_columns } from "./tables";
import { CreateUser } from "./components/createuser";
import { CreateBulkUser } from "./components/createbulkuser";

const AgentsView = () => {
  const {
    dispatcher,
    loading_dispatcher,
    visible,
    setVisible,
    setRow,
    onRowClick,
    setShowForm,
    showForm,
    handleSetStatus,
  } = useContext(PageContext);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const dispatcher_columns = [
    {
      title: "Status",
      render: (item) => (
        <Switch
          defaultChecked={item.status === "approved"}
          onChange={() => handleSetStatus(item)}
        />
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("username"),
    },
    {
      title: "Action",
      render: (item) => (
        <div>
          <EditOutlined
            className="cursor-pointer"
            onClick={() => onRowClick(item)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-3xl font-bold secondary-color">Dispatchers</p>
      <div className="mt-10 flex flex-row">
        <div className="w-screen mr-4">
          <Button
            className="w-20 mb-5"
            type="primary"
            onClick={() => {
              setRow({});
              setShowForm(true);
            }}
          >
            Create dispatcher
          </Button>
          <p className="text-xs font-bold">Dispatcher list</p>
          {loading_dispatcher ? (
            <>
              <div className="flex h-full">
                <Spin className="self-center" />
              </div>
            </>
          ) : (
            <>
              <Table
                dataSource={dispatcher?.dispatchers ?? []}
                columns={dispatcher_columns}
              />
            </>
          )}
        </div>
        <CreateUser />
        <CreateBulkUser />
      </div>
    </div>
  );
};

export default AgentsView;
