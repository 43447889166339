import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Form, Input, Button, Spin, Card } from "antd";

const RegistrationView = (props) => {
  const { form, handleFinishForm, loading } = useContext(PageContext);
  return (
    <div className="flex flex-row h-screen">
      <div
        className="flex flex-col h-full justify-center items-center w-full"
        style={{
          backgroundColor: "#F4A261",
        }}
      >
        <div className="flex items-center justify-center bg-white h-full w-4/5 my-32 rounded-lg shadow-lg">
          <Card className="flex flex-row w-3/5 justify-center items-center">
            <div className="flex flex-row">
              <div>
                <p className="font-bold text-xl primary-color">Bus Pass Cebu</p>
                <p className="font-bold text-base mb-5 secondary-color">
                  Dispatcher Portal
                </p>
              </div>
            </div>
            <div>
              <Form layout="vertical" form={form} onFinish={handleFinishForm}>
                <Form.Item label="Username" name="username" required>
                  <Input placeholder="Enter username" />
                </Form.Item>
                <Form.Item label="Password" name="password" required>
                  <Input.Password placeholder="password" />
                </Form.Item>
                <Button
                  className="w-full"
                  disabled={loading}
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? <Spin /> : "Login"}
                </Button>
              </Form>
            </div>
          </Card>
          <div
            className="hidden lg:flex flex-col h-full justify-center items-center w-2/5"
            style={{
              backgroundImage: `url(${require("../../assets/login2.jpg")})`,
              backgroundSize: "cover",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RegistrationView;
