import React, { useState } from "react";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import { useMutation, useSubscription } from "@apollo/react-hooks";
import _ from "lodash";
import { AGENTS } from "../../lib/subscriptions";
import { UPDATE_USER_STATUS } from "../../lib/mutations";
import { GET_BUSES } from "../../lib/query";

const BusesController = () => {
  const [visible, setVisible] = useState({
    agent: false,
    bulkagent: false,
  });
  const [Bulkvalue, setBulkValue] = useState(0);
  const [resetPassModal, setResetPassModal] = useState(false);
  const [row, setRow] = useState({});
  const [id, setId] = useState();
  const [showForm, setShowForm] = useState(false);

  const { data: buses, loading: loading_buses } = useSubscription(GET_BUSES);

  const [updateStatus] = useMutation(UPDATE_USER_STATUS);

  const onRowClick = (item) => {
    setRow(item);
    setShowForm(true);
  };

  const showPassModal = () => {
    setResetPassModal(true);
    setVisible({ ...visible, agent: false });
  };

  const closePassModal = () => {
    setResetPassModal(false);
    setVisible({ ...visible, agent: true });
  };

  const handleSetStatus = async (item) => {
    await updateStatus({
      variables: {
        id: item.id,
        status: item.status === "approved" ? "denied" : "approved",
      },
    });
  };

  const pageValues = {
    buses,
    loading_buses,
    visible,
    row,
    resetPassModal,
    id,
    showForm,
    setVisible,
    onRowClick,
    setRow,
    setBulkValue,
    Bulkvalue,
    closePassModal,
    showPassModal,
    setId,
    setShowForm,
    handleSetStatus,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default BusesController;
